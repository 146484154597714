/* eslint-disable require-jsdoc */
import React, { Component } from "react";

class AppInstructions extends Component {
  render() {
    return (
      <div className="row">
        <h4>
          Choose appropriate difficulty to begin exercise! Note: This activity
          requires headphones or speakers.
        </h4>
      </div>
    );
  }
}

export default AppInstructions;
