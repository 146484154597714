import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { actions as exerciseActions } from "../../../../reducers/lab10/ExerciseReducer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Simulation from "../components/Simulation";
import {
  EXERCISE_PLAYING,
  MIN_USER_ATTEMPTS,
  SIMULATION_ENDED,
  SIMULATION_IDLE,
  SIMULATION_STARTED,
} from "../../../../constants/lab10";
import useScroll from "../../../../use-hooks/useScroll";
import { navigate } from "@reach/router";

const TrainingAI = (props) => {
  useScroll();
  const [limitReached, setLimitReach] = useState(false);

  /**
   * Executed on mount
   */
  useEffect(() => {
    props.actions.updateState(EXERCISE_PLAYING);
    props.actions.disableSimulationCover();
    props.actions.enableUserInput();
    props.actions.idleSimulation();
  }, []);

  /**
   * Enable/Disable properties based on the SIMULATION state
   */
  useEffect(() => {
    switch (props.simulationStatus) {
      case SIMULATION_STARTED:
        props.actions.enableUserInput();
        props.actions.disableSimulationCover();
        break;
      case SIMULATION_ENDED:
        break;
    }
  }, [props.simulationStatus]);

  /**
   * Update state if the minimum amount user attempts has been reached
   */
  useEffect(() => {
    if (props.userAttempts >= MIN_USER_ATTEMPTS && !limitReached) {
      setLimitReach(true);
      props.actions.disableUserInput();
      props.actions.enableSimulationCover();
    }
  }, [props.userAttempts]);

  /**
   * Redirect the user to the following page
   */
  const handleContinue = () => {
    return navigate("/Lab10/Exercise/TrainingAI/Repair");
  };

  return (
    <div>
      {props.simulationStatus === SIMULATION_IDLE && (
        <div>
          {limitReached ? (
            <Fragment>
              <p className={"playthrough__sentence"}>
                Awesome, it works. Next, you need to generate data for the
                neural network. This data can be generated by training the AI to
                avoid the falling shapes, which will be the goal for this phase
                of the exercise. <br /> You will be given{" "}
                {props.trainingDuration} seconds to avoid the falling the
                shapes.
              </p>
              <div>
                <p className={"tw-text-xl tw-font-bold"}>
                  Objective: Click <i>Start</i> to commence the training
                  exercise.
                </p>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div>
                <p className={"tw-text-xl tw-font-bold"}>
                  Objective: Now, try to move the object using the on-screen
                  buttons or your keyboard.
                </p>
              </div>
            </Fragment>
          )}
        </div>
      )}
      {props.simulationStatus === SIMULATION_STARTED && (
        <div>
          <div>
            <p className={"tw-text-xl tw-font-bold"}>
              Objective: Avoid the falling shapes.
            </p>
          </div>
        </div>
      )}
      {props.simulationStatus === SIMULATION_ENDED && (
        <div>
          <div>
            <p className={"tw-text-xl tw-font-bold"}>
              Objective: Proceed to the next part of this exercise.
            </p>
          </div>
        </div>
      )}
      <Simulation />
      {props.simulationStatus === SIMULATION_ENDED && (
        <div className={"tw-mt-6 tw-flex tw-justify-end"}>
          <button
            className="btn btn-primary text-black btn-xl text-uppercase"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userAttempts, trainingDuration, simulationStatus } = state.exercise10;
  return {
    userAttempts,
    trainingDuration,
    simulationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...exerciseActions }, dispatch),
  };
};

TrainingAI.propTypes = {
  userAttempts: PropTypes.number,
  trainingDuration: PropTypes.number,
  simulationStatus: PropTypes.string,
  actions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingAI);
